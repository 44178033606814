document.querySelector('form').onsubmit = function(e) {
	var form = this;

	var reportError = function(error) {
		if (error) {
			alert(error);
		} else {
			// TODO provide email address?
			alert('エラーが発生しました。');
		}
	}

	var disableAll = function(value) {
		form.querySelectorAll('input, textarea').forEach(function(e) {
			e.disabled = value;
		});
	}

	var submit = this.submit;
	var formData = new FormData(this);
	disableAll(true);

	var xhr = new XMLHttpRequest();

	xhr.onload = function() {
		if (xhr.status != 200) {
			reportError(xhr.response);
			disableAll(false);
		} else {
			console.log(xhr.response);
			document.getElementById('contact').classList.add('complete')
		}
	};

	xhr.onerror = function() {
		reportError();
		disableAll(false);
	};

	xhr.open('POST', '/contact.php');
	xhr.send(formData);

	return false;
}
